import React from "react"

function DbtIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      preserveAspectRatio="xMidYMid"
      viewBox="0 120 512 196"
    >
      <path
        fill="#262A38"
        d="M334.734 30.222v133.434h-27.041v-13.609c-2.828 4.772-6.892 8.66-11.841 11.135-5.302 2.65-11.312 4.064-17.319 4.064-7.423.177-14.847-1.944-21.032-6.185-6.362-4.242-11.311-10.074-14.493-16.967-3.534-7.953-5.302-16.436-5.126-25.096a57.318 57.318 0 015.126-24.92c3.182-6.715 8.131-12.548 14.317-16.612 6.361-4.065 13.785-6.01 21.208-5.833 5.832 0 11.663 1.237 16.789 3.888 4.95 2.475 9.013 6.186 11.84 10.781V39.235l27.572-9.013zm-32.519 107.1c3.534-4.595 5.478-11.31 5.478-19.97 0-8.66-1.766-15.2-5.478-19.795-3.712-4.595-9.545-7.246-15.553-7.069-6.01-.354-11.84 2.297-15.73 6.893-3.71 4.595-5.656 11.134-5.656 19.617 0 8.66 1.946 15.376 5.656 20.148 3.711 4.772 9.72 7.423 15.73 7.246 6.716 0 12.017-2.474 15.553-7.07zM431.41 75.29a38.305 38.305 0 0114.314 16.613 57.32 57.32 0 015.126 24.92c.176 8.66-1.592 17.32-5.126 25.096-3.181 6.892-8.129 12.724-14.493 16.966-6.185 4.065-13.608 6.186-21.03 6.01a34.759 34.759 0 01-17.32-4.066c-4.948-2.474-9.014-6.362-11.665-11.134v13.785h-27.216V39.235l27.394-9.013v54.434c2.65-4.772 6.715-8.66 11.663-11.135 5.303-2.827 11.135-4.241 17.144-4.065 7.422-.176 14.845 1.945 21.209 5.833zm-13.433 61.857c3.711-4.772 5.48-11.488 5.48-20.325 0-8.483-1.769-15.022-5.48-19.617-3.712-4.595-8.838-6.893-15.553-6.893-6.008-.353-11.841 2.298-15.729 7.07-3.712 4.771-5.48 11.31-5.48 19.794 0 8.66 1.768 15.376 5.48 19.97 3.712 4.773 9.543 7.424 15.729 7.07 6.01.354 11.84-2.297 15.553-7.07zm70.694-44.89v35.7c0 5.125 1.414 8.836 3.89 11.134 2.827 2.474 6.537 3.711 10.427 3.534 3.003 0 6.185-.53 9.013-1.237v21.208c-5.126 1.768-10.427 2.651-15.73 2.475-11.31 0-19.971-3.182-25.98-9.544-6.008-6.362-9.014-15.376-9.014-27.04V50.192l27.394-8.836v30.398H512v20.501h-23.33z"
      ></path>
      <path
        fill="#FF694A"
        d="M187.161 8.13c4.595 4.418 7.6 10.25 8.307 16.613 0 2.65-.707 4.418-2.298 7.776-1.59 3.358-21.208 37.291-27.04 46.658-3.358 5.479-5.125 12.018-5.125 18.38 0 6.54 1.767 12.902 5.125 18.38 5.832 9.367 25.45 43.477 27.04 46.835 1.591 3.358 2.298 4.949 2.298 7.6-.707 6.362-3.535 12.194-8.13 16.436-4.418 4.595-10.25 7.6-16.436 8.13-2.651 0-4.418-.707-7.6-2.298-3.181-1.59-37.82-20.678-47.188-26.51-.707-.353-1.414-.884-2.297-1.237l-46.305-27.394c1.06 8.837 4.949 17.32 11.311 23.506a35.35 35.35 0 003.889 3.358c-1.06.53-2.298 1.06-3.358 1.767-9.367 5.832-43.477 25.45-46.835 27.04-3.358 1.591-4.948 2.298-7.776 2.298-6.363-.707-12.195-3.535-16.437-8.13-4.595-4.418-7.6-10.25-8.306-16.613.177-2.65.884-5.302 2.298-7.6 1.59-3.357 21.208-37.467 27.04-46.834 3.358-5.479 5.125-11.841 5.125-18.38 0-6.54-1.767-12.902-5.125-18.38C23.506 69.81 3.71 35.7 2.298 32.341.884 30.045.177 27.394 0 24.742.707 18.38 3.535 12.549 8.13 8.13 12.548 3.535 18.38.707 24.743 0c2.65.177 5.302.884 7.776 2.298 2.828 1.237 27.747 15.729 41.002 23.505l3.005 1.768c1.06.706 1.944 1.237 2.65 1.59l1.415.884 47.188 27.924c-1.06-10.604-6.54-20.325-15.023-26.864 1.06-.53 2.298-1.06 3.358-1.767 9.367-5.832 43.477-25.627 46.835-27.04C165.246.884 167.897.177 170.725 0a27.056 27.056 0 0116.436 8.13zm-86.953 102.152l10.074-10.074c1.414-1.414 1.414-3.534 0-4.948l-10.074-10.074c-1.414-1.414-3.534-1.414-4.948 0L85.186 95.26c-1.414 1.414-1.414 3.534 0 4.948l10.074 10.074c1.237 1.237 3.534 1.237 4.948 0z"
      ></path>
    </svg>
  )
}

export default DbtIcon
