import React from "react"
import styled from "styled-components"
import ContentWrapper from "../styles/contentWrapper"
import TechnologyIcon from "./icons"

const StyledFooter = styled.footer`
  width: 100%;
  padding: 1rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
`

const StyledLink = styled.a`
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1px;
  padding: 0 0.5rem;
`

const Footer = () => (
  <StyledFooter>
    <StyledContentWrapper>
      <SocialLinks>
        <StyledLink href="https://github.com/bengimbel" target="_blank">
          <TechnologyIcon name="github" />
        </StyledLink>
        <StyledLink
          href="https://www.linkedin.com/in/bengimbel/"
          target="_blank"
        >
          <TechnologyIcon name="linkedin" />
        </StyledLink>
      </SocialLinks>
    </StyledContentWrapper>
  </StyledFooter>
)

export default Footer
