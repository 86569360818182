import React from "react"

const CloudflareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 32 32"
    >
      <path
        fill="#fff"
        d="M8.16 23h21.177v-5.86l-4.023-2.307-.694-.3-16.46.113z"
      ></path>
      <path
        fill="#f38020"
        d="M22.012 22.222c.197-.675.122-1.294-.206-1.754-.3-.422-.807-.666-1.416-.694l-11.545-.15c-.075 0-.14-.038-.178-.094s-.047-.13-.028-.206a.315.315 0 01.272-.206l11.648-.15c1.38-.066 2.88-1.182 3.404-2.55l.666-1.735a.38.38 0 00.02-.225 7.575 7.575 0 00-7.4-5.927 7.585 7.585 0 00-7.184 5.15 3.46 3.46 0 00-2.392-.666c-1.604.16-2.9 1.444-3.048 3.048a3.58 3.58 0 00.084 1.191A4.84 4.84 0 000 22.1c0 .234.02.47.047.703.02.113.113.197.225.197H21.58a.29.29 0 00.272-.206l.16-.572z"
      ></path>
      <path
        fill="#faae40"
        d="M25.688 14.803l-.32.01c-.075 0-.14.056-.17.13l-.45 1.566c-.197.675-.122 1.294.206 1.754.3.422.807.666 1.416.694l2.457.15c.075 0 .14.038.178.094s.047.14.028.206a.315.315 0 01-.272.206l-2.56.15c-1.388.066-2.88 1.182-3.404 2.55l-.188.478c-.038.094.028.188.13.188h8.797a.23.23 0 00.225-.169A6.41 6.41 0 0032 21.106a6.32 6.32 0 00-6.312-6.302"
      ></path>
    </svg>
  )
}

export default CloudflareIcon
