import React from "react"

const ElixirIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <linearGradient
          id="a"
          x1="17.249"
          x2="14.973"
          y1="-335.597"
          y2="-309.994"
          gradientTransform="matrix(1 0 0 -1 0 -306)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.01" stopColor="#f7f6f8" stopOpacity="0.04"></stop>
          <stop offset="0.09" stopColor="#aa9cb3" stopOpacity="0.39"></stop>
          <stop offset="0.2" stopColor="#6f567e" stopOpacity="0.66"></stop>
          <stop offset="0.32" stopColor="#452459" stopOpacity="0.86"></stop>
          <stop offset="0.5" stopColor="#2d0843" stopOpacity="0.97"></stop>
          <stop offset="1" stopColor="#26003d"></stop>
        </linearGradient>
      </defs>
      <path
        fill="#7c648f"
        d="M17.8 8.591c2.079 4.584 7.64 6.5 7.141 12.474-.582 7.017-5.551 8.784-8.316 8.909a8.815 8.815 0 01-9.4-7.485C5.618 15.046 12.655 3.632 16.709 2A15.083 15.083 0 0017.8 8.591zm-.166 18.857a.423.423 0 00-.057-.327c-.593-1.1-5.81-1.645-6.907-1.752a8.272 8.272 0 001.635 1.3 7.766 7.766 0 002.814 1.041c.803.121 2.348.223 2.516-.263z"
      ></path>
      <path
        fill="#26003d"
        d="M18.248 10.618c4.47 4.823 6.445 4.979 6.237 10.478-.249 6.476-4.543 8.191-7.058 8.42-4.2.551-7.495-1.528-8.358-5.686-1.757-6.767 3.909-17.807 7.568-19.47a15.323 15.323 0 001.621 6.24z"
        opacity="0.25"
      ></path>
      <path
        fill="url(#a)"
        d="M17.385 9.921C20.369 14 24.319 13.7 25 19.641c.094 6.923-3.6 9.283-6.237 9.948-4.5 1.279-8.514-.645-10.094-5-3.035-7 2.651-18.514 6.31-20.915a15.083 15.083 0 002.37 6.237z"
        opacity="0.75"
      ></path>
    </svg>
  )
}

export default ElixirIcon
