import React from "react"
import styled from "styled-components"

const StyledLogo = styled.div`
  position: relative;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 400;
  color: ${({ theme, color }) => theme.colors[color] || color};

  /* Disable effects when sidebar is open */
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 2rem;
  }
`

const Logo = ({ size, color }) => (
  <StyledLogo color={color} size={size}>
    Ben Gimbel
  </StyledLogo>
)

export default Logo
