import React, { useState } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import ContentWrapper from "../styles/contentWrapper"
import Logo from "./logo"
import Sidebar from "./sidebar"
import Navbar from "./navbar"

const StyledHeader = motion.custom(styled.header`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  background: ${({ theme }) => theme.colors.background};
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 0 2.5rem;
      padding: 0 2rem;
    }
  }
`

// https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const StyledBurger = styled.button`
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 0.625rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`
const MobileMenu = styled.div`
  display: flex;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const Header = () => {
  const [open, setOpen] = useState(false)
  const controls = useAnimation()

  return (
    <StyledHeader initial={{ opacity: 1, y: 0 }} animate={controls}>
      <Helmet bodyAttributes={{ class: open ? "blur" : "" }} />
      <StyledContentWrapper>
        <Link
          style={{ padding: "0" }}
          className="nav-link"
          to="/"
          aria-label="home"
        >
          <Logo />
        </Link>
        <MobileMenu>
          <StyledBurger
            aria-controls="sidebar"
            open={open}
            onClick={() => setOpen(!open)}
          >
            <div />
            <div />
            <div />
          </StyledBurger>
          <Sidebar id="sidebar" open={open} setOpen={setOpen} />
        </MobileMenu>
        <Navbar />
      </StyledContentWrapper>
    </StyledHeader>
  )
}

export default Header
