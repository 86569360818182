import React from "react"

import ReactIcon from "./react"
import ReduxIcon from "./redux"
import GraphQLIcon from "./graphql"
import NodeIcon from "./node"
import KubernetesIcon from "./kubernetes"
import AwsIcon from "./aws"
import LinkedinIcon from "./linkedin"
import GitHubIcon from "./github"
import CloudflareIcon from "./cloudflare"
import ElixirIcon from "./elixir"
import PythonIcon from "./python"
import TypescriptIcon from "./typescript"
import GolangIcon from "./golang"
import KafkaIcon from "./kafka"
import PostgresIcon from "./postgres"
import DbtIcon from "./dbt"
import Datadog from "./datadog"

const TechnologyIcon = ({ name }) => {
  switch (name.toLowerCase()) {
    case "react":
      return <ReactIcon />
    case "redux":
      return <ReduxIcon />
    case "graphql":
      return <GraphQLIcon />
    case "node":
      return <NodeIcon />
    case "kubernetes":
      return <KubernetesIcon />
    case "aws":
      return <AwsIcon />
    case "linkedin":
      return <LinkedinIcon />
    case "github":
      return <GitHubIcon />
    case "cloudflare":
      return <CloudflareIcon />
    case "elixir":
      return <ElixirIcon />
    case "python":
      return <PythonIcon />
    case "typescript":
      return <TypescriptIcon />
    case "golang":
      return <GolangIcon />
    case "kafka":
      return <KafkaIcon />
    case "postgres":
      return <PostgresIcon />
    case "dbt":
      return <DbtIcon />
    case "datadog":
      return <Datadog />
    default:
      return null
  }
}

export default TechnologyIcon
