import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import config from "../../config"

const { navLinks } = config

const StyledNav = styled.nav`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 31.25rem;
    background: ${({ theme }) => theme.colors.background};
  }
  .nav-link {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    position: relative;
    margin: 0 0 0 1.25rem;
    padding: 0;
    &:hover: {
      color: #0056b3;
    }
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      width: 0%;
      bottom: -0.125rem;
    }
    &:hover::before {
      width: 100%;
      background-color: #0056b3;
    }
  }
  .active-link {
    color: #007bff;
    &::before {
      background-color: #007bff;
      color: #007bff;
    }
    &:hover::before {
      background-color: #007bff;
      color: #007bff;
    }
  }
`

const Navbar = () => {
  const { menu } = navLinks
  return (
    <StyledNav>
      {menu.map(({ name, url }, key) => (
        <Link
          activeClassName="active-link"
          className="nav-link"
          key={key}
          to={url}
        >
          {name}
        </Link>
      ))}
    </StyledNav>
  )
}

export default Navbar
